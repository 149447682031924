import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const WindowAndDoorFinancingPage = () => {
  return (
    <Layout>
      <Seo
        title={"Customer Financing for Window and Door Contractors"}
        description={
          "Make the smart move of adding the option for customer financing for window and door contractors and take your business to the next level."
        }
      />
      <Helmet>
        <body className="window-door-financing industry" />
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "Contractor Financing for Home Improvement",
 "image": "",
 "brand": {
   "@type": "Brand",
   "name": "Regions"
   }
   }`}
        </script>
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="remodeling-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2023/12/windowDoorsFinancing-scaled-1.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Customer Financing for <br /> Window and Door Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block bg-light-gray">
        <div className="container">
          <div className="justify-content-center">
            <p>
              Adding the option for customer financing for window and door
              contractors is a smart move all-round. Offering flexible payment
              options is the key to taking your business to the next level and
              opening doors to more business, revenue, and success. Not sure how
              to start offering customer financing? Regions | EnerBank experts
              handle everything, from getting your customers approved to
              delivering the funds to your business at incredible speed.
            </p>

            <p className="font-weight-bold text-center short-divider">
              Give your customers the flexibility they want with EnerBank
              payment options.&nbsp;
              <Link to="/sign-up/" className="text-blue">
                Sign up today!
              </Link>
              &nbsp;
            </p>
          </div>
        </div>
      </section>
      <div className="section-header">
        <h2 className="text-center bg-blue">
          How Can Offering Financing for Contractors Benefit You?
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-4">
                Many of your customers would rather choose financing to complete
                their project, instead of paying cash. There are multiple
                business-centered benefits that you can look forward to as you
                offer them the loans they want.
              </p>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/12/budget-boost.png"
                    alt="Boost Project Budgets"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">Boost Project Budgets</h3>
                  <p>
                    Let's say a customer wants to install new doors in their
                    home. If their only option is to pay out of pocket for the
                    project, they may decide to only do one or a few doors now
                    and wait to do the rest, rather than all of them now.
                  </p>
                  <p className="mt-2">
                    When you offer the option of contractor financing for
                    windows or doors, your customers can change their simple
                    door upgrade into a full-blown transformation. You'll have
                    more opportunities to sell better-quality materials and
                    elite products to your clients. For you, this means
                    higher-revenue projects and, more importantly, higher
                    profit. If you're committing resources to a job anyway, you
                    might as well make it a better-paying job!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/12/standout-competition.png"
                    alt="Stand Out from the Competition"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">
                    Stand Out from the Competition
                  </h3>
                  <p>
                    Payment flexibility is the ultimate way to stand out and
                    gain more clients that might have previously gone to your
                    competitors. Not everyone offers the ability to finance. The
                    flexibility you provide will convince customers to use your
                    business over others — even if they don't necessarily need
                    to finance.
                  </p>
                  <p className="mt-2">
                    Simply having the option to finance elevates your business
                    and makes you appear more professional and established.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/cancel1.png"
                    alt="Avoid Cancellations"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">Avoid Cancellations</h3>
                  <p>
                    Payment issues may cause many of the cancellations you
                    experience. Financing ties the project to your company,
                    making cancellations much less common. Fewer cancellations
                    mean more reliable business — and more reliable cash flow.
                    In fact, 94% of homeowners who are approved for a EnerBank
                    loan end up completing the job with the contractor who
                    offered the financing.*
                  </p>
                  <p className="mt-2">
                    <em>
                      *Based on the Brickyard Study commissioned by EnerBank
                      USA. <br /> 2018.
                    </em>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <p className="font-weight-bold text-center">
                Reap the benefits of offering customer financing and increase
                revenue.&nbsp;
                <Link to="/sign-up/">Get started now!</Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
        <h2 className="text-center bg-blue">How Does it Work?</h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 justify-content-center">
              <p className="mb-4">
                If you're interested in offering customer financing but don't
                know where to begin, make things simple with an EnerBank loan
                program. When you work with us, we handle all the financing so
                you can continue what you're already doing without added hassle.
              </p>
              <p className="mt-2">
                We are experts in window and door&nbsp;
                <Link to="/contractor-financing/">contractor financing</Link>
                &nbsp;and make it easier than ever for both you and your
                customers to get started with our loan program. We offer three
                convenient paperless application methods and six ways to make
                payments. The ease of use, and high-level customer experience we
                provide, will reflect highly on you and your business when you
                work with us.
              </p>
              <p className="mt-2">
                We'll also pair you with an experienced relationship manager to
                help support your business and team through this transition to
                customer financing. With the high-quality marketing materials we
                provide, your team will learn the ins and outs of financing with
                EnerBank. You can give your clients all the information they
                need when you're trying to win a project.
              </p>
              <div className="short-divider">
                <p className="font-weight-bold text-center">
                  Work with a company that functions hand-in-hand with your
                  business.
                  <br />
                  <Link to="/sign-up/" className="text-blue">
                    Fill out an interest form&nbsp;
                  </Link>
                  today to get started with EnerBank and learn more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
        <h2 className="text-center bg-blue">
          Why EnerBank for Window and Door Contractor Financing?
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                EnerBank USA, now a part of Regions Bank, is a leader in the
                industry, with over two decades of exclusive home improvement
                lending experience. That means our focus is on delivering the
                best financial products and services for home improvement
                professionals and homeowners.
              </p>
              <p className="mt-2">
                Whether you're an industry organization looking to create a
                white-label loan program, a contractor looking to grow your
                business, or a homeowner starting a home improvement project,
                our paperless loan application, quick approvals, and easy
                payment disbursements make funding home improvements a breeze.
                We offer excellent customer service, the tools to help
                contractors succeed, and payment options custom-designed
                exclusively for homeowners who want to make their house a home.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
        <h2 className="text-center bg-blue">
          Check Out the EnerBank Difference!
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 align-items-center justify-content-center">
              <ul className="enerbank-difference">
                <li className="enerbank-difference-item">No hidden fees</li>
                <li className="enerbank-difference-item">
                  High approval rates
                </li>
                <li className="enerbank-difference-item">
                  Commitment to an excellent customer experience
                </li>
                <li className="enerbank-difference-item">
                  State-of-the-art technology for a smooth application process
                </li>
                <li className="enerbank-difference-item">
                  Custom marketing materials to support your business growth
                </li>
                <li className="enerbank-difference-item">
                  Dedicated relationship manager who understands your industry
                </li>
                <li className="enerbank-difference-item">
                  On-demand and live training in best practices to optimize your
                  experience
                </li>
              </ul>
            </div>
            <div className="col-12 short-divider">
              <p className="font-weight-bold text-center">
                Contact us today to learn more about growing your business
                through a payment options program!
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="h2 font-weight-bold text-blue my-4">
                Get Started with EnerBank!
              </h3>
              <p>
                Are you ready to start landing more clients and bigger projects
                with the selling point of flexible window and door contractor
                financing options? Give your customers the option to finance
                with EnerBank and see where it takes your business.
              </p>
              <p>
                <Link
                  className="btn btn-primary mt-4 py-2 px-3"
                  href="/sign-up/"
                >
                  Get Started today!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WindowAndDoorFinancingPage
